import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async getProduct(context, { p_id, mp, date, date2, fbs }) {
            const { data } = await Vue.api.get("/getProduct", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    fbs,
                },
            });
            return data;
        },
        async getProductByName(context, { query, mp }) {
            const { data } = await Vue.api.get("/getProductByName", {
                params: {
                    query,
                    mp,
                },
            });
            return data;
        },

        async productOverviewAll(context, queryParams) {
            const { data } = await Vue.api.get("/productOverviewAll", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },

        /*
        async productOverviewAll(context, { mp, p_id, date, date2, fbs }) {
            const { data } = await Vue.api.get("/productOverviewAll", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    fbs,
                    // like _CH_DROP_CACHE_
                    ...queryParams,
                },
            });
            return data;
        },
        */

        async getProductDays(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getProductDays", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getProductCategoriesPositions(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/getProductCategoriesPositions",
                {
                    params: {
                        mp,
                        p_id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        //kwd
        async getProductKeywordsPositions(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getProductKeywordsPositions", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getProductSizes(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getProductSizes", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getProductStores(context, queryParams) {
            const { mp, p_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getProductStores", {
                params: {
                    mp,
                    p_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
    },
};
