//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Warning",
  props: {
    text: String,
    textBtn: String,
    link: String
  }
};