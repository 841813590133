import Vue from "vue";
import { filtersToObject, jsonToFormData } from "@/utils/newModule";

export default {
    namespaced: true,
    state: {
        hasProjects: true,
        hasProjectsLoaded: false,
        projects: [],
        queriesLimit: null,
        currentQueriesCount: null,
    },
    mutations: {
        SET_HAS_PROJECTS(state, data) {
            state.hasProjects = data;
            state.hasProjectsLoaded = true;
        },
        SET_PROJECTS(state, data) {
            state.projects = data;
        },
        SET_QUERIES_LIMIT(state, data) {
            state.queriesLimit = data;
        },
        SET_CURRENT_QUERIES_COUNT(state, data) {
            state.currentQueriesCount = data;
        },
    },
    actions: {
        async setHasProjects({ dispatch }) {
            await dispatch("getProjects", {});
        },
        async getQueriesStats({ commit }) {
            const { data } = await Vue.api.post(
                `/service/seller/get_queries_stats`
            );

            ////console.log(data);

            commit("SET_QUERIES_LIMIT", data?.data.limit);
            commit("SET_CURRENT_QUERIES_COUNT", data?.data.count);
            return data;
        },
        async getProjects(
            { commit },
            { search = "", sort = "created_at", sort_dir = "desc" }
        ) {
            let params = {
                take: 1000,
                skip: 0,
                page: 0,
                pageSize: 1000,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
                "filter[logic]": "and",
                "filter[filters][0][field]": "name",
                "filter[filters][0][operator]": "contains",
            };
            if (search.length > 0) {
                params["filter[filters][0][value]"] = search;
            }
            const { data } = await Vue.api.post(
                "/service/seller/get_projects",
                jsonToFormData(params)
            );
            if (search.length === 0) {
                commit("SET_HAS_PROJECTS", !!data?.total);
            }
            commit("SET_PROJECTS", data?.data);
            return data;
        },
        async getProjectHistory(ctx, { date_from, date_to, projectId }) {
            let params = {
                take: 10000,
                skip: 0,
                page: 0,
                pageSize: 10000,
                "filter[filters][0][field]": "snapshotDate",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date_from,
                "filter[filters][1][field]": "snapshotDate",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date_to,
                "filter[filters][2][field]": "project_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value]": projectId,
            };
            const { data } = await Vue.api.post(
                "/service/seller/get_projects_history",
                jsonToFormData(params)
            );
            return data;
        },
        async createProject(ctx, { name, datasource }) {
            const { data } = await Vue.api.post(
                "/service/seller/create_projects",
                {
                    name,
                    datasource,
                }
            );
            return data;
        },
        async createProjectQueries(ctx, { project_id, query }) {
            const { data } = await Vue.api.post(
                "/service/seller/create_project_queries",
                {
                    project_id,
                    query,
                }
            );
            return data;
        },
        async createProjectProducts(ctx, { project_id, own, sku }) {
            const { data } = await Vue.api.post(
                "/service/seller/create_project_products",
                {
                    project_id,
                    own,
                    sku,
                }
            );
            return data;
        },
        async removeProject(ctx, { id }) {
            const { data } = await Vue.api.post(
                "/service/seller/destroy_projects",
                {
                    id,
                }
            );
            return data;
        },
        async updateProjects(ctx, { id, name, datasource, status }) {
            const { data } = await Vue.api.post(
                "/service/seller/update_projects",
                {
                    id,
                    name,
                    datasource,
                    status,
                }
            );
            return data;
        },
        async getProjectQueries(ctx, { id }) {
            let params = {
                project_id: id,
                take: 1000,
                skip: 0,
                page: 0,
                pageSize: 1000,
                "filter[logic]": "and",
                "filter[filters][0][field]": "project_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value]": id,
            };
            const { data } = await Vue.api.post(
                "/service/seller/get_project_queries",
                jsonToFormData(params)
            );
            return data;
        },
        async getProjectProducts(ctx, { id, own }) {
            let params = {
                project_id: id,
                own: own,
                take: 1000,
                skip: 0,
                page: 0,
                pageSize: 1000,
                "filter[logic]": "and",
                "filter[filters][0][field]": "own",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value]": own,
                "filter[filters][1][field]": "project_id",
                "filter[filters][1][operator]": "=",
                "filter[filters][1][value]": id,
            };
            const { data } = await Vue.api.post(
                "/service/seller/get_project_products",
                jsonToFormData(params)
            );
            return data;
        },
        async removeProjectQueries(ctx, { project_id }) {
            let params = {
                project_id,
            };
            const { data } = await Vue.api.post(
                "/service/seller/destroy_project_queries",
                params
            );
            return data;
        },
        async removeProjectProducts(ctx, { project_id, own }) {
            let params = {
                project_id,
                own,
            };
            const { data } = await Vue.api.post(
                "/service/seller/destroy_project_products",
                params
            );
            return data;
        },
        async replaceProjectQueries(ctx, { id, queries }) {
            let params = {
                project_id: id,
                "queries[]": queries,
            };
            const { data } = await Vue.api.post(
                "/service/seller/replace_project_queries",
                jsonToFormData(params)
            );
            return data;
        },
        async replaceProjectProducts(ctx, { id, products, own }) {
            let params = {
                project_id: id,
                "sku[]": products,
                own,
            };
            const { data } = await Vue.api.post(
                "/service/seller/replace_project_products",
                jsonToFormData(params)
            );
            return data;
        },
        async getMonitoringQueries(
            ctx,
            {
                page_size = 10000,
                page = 1,
                project_id,
                date_from,
                date_to,
                own,
                sort = "query",
                sort_dir = "desc",
                productId,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "sort[0][field]": sort === "dynamic" ? "query" : sort,
                "sort[0][dir]": sort_dir,
                "filter[logic]": "and",
                "filter[filters][0][field]": "date_from",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date_from,
                "filter[filters][1][field]": "date_to",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date_to,
                "filter[filters][2][field]": "project_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value]": project_id,
                "filter[filters][3][field]": "own",
                "filter[filters][3][operator]": "=",
                "filter[filters][3][value]": own,
                ...otherFilters,
            };
            if (productId) {
                params["filter[filters][4][field]"] = "p_id";
                params["filter[filters][4][operator]"] = "=";
                params["filter[filters][4][value]"] = productId;
            }
            const { data } = await Vue.api.post(
                "/service/seller/get_monitoring_queries",
                jsonToFormData(params)
            );
            return data;
        },
        async getMonitoringQueriesCompare(
            ctx,
            {
                page_size = 10000,
                page = 1,
                project_id,
                date_from,
                date_to,
                own,
                sort = "query",
                sort_dir = "desc",
                product_id,
                product_id_not_own,
            }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "sort[0][field]": sort === "dynamic" ? "query" : sort,
                "sort[0][dir]": sort_dir,
                "filter[logic]": "and",
                "filter[filters][0][field]": "date_from",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date_from,
                "filter[filters][1][field]": "date_to",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date_to,
                "filter[filters][2][field]": "project_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value]": project_id,
                "filter[filters][3][field]": "own",
                "filter[filters][3][operator]": "=",
                "filter[filters][3][value]": own,
                "filter[filters][4][field]": "product_id",
                "filter[filters][4][operator]": "=",
                "filter[filters][4][value]": product_id,
                "filter[filters][5][field]": "product_id_not_own",
                "filter[filters][5][operator]": "=",
                "filter[filters][5][value]": product_id_not_own,
            };
            const { data } = await Vue.api.post(
                "/service/seller/get_monitoring_queries_compare",
                jsonToFormData(params)
            );
            return data;
        },
        async getProductById(
            context,
            { page_size = 10000, page = 1, sku, mp }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": sku,
            };
            if (mp === "oz") {
                params["filter[filters][0][field]"] = "fbo_sku";
            } else {
                params["filter[filters][0][field]"] = "sku";
            }
            const { data } = await Vue.api.post(
                `/service/seller/${mp}/get_products`,
                jsonToFormData(params)
            );
            return data;
        },
        async getMonitoringQueriesBorderDates(context, { project_id }) {
            let params = {
                project_id,
            };
            const { data } = await Vue.api.post(
                `/service/seller/get_monitoring_queries_border_dates`,
                jsonToFormData(params)
            );
            return data;
        },
        async getSearchQueriesByCategory(
            context,
            {
                page = 1,
                page_size = 20,
                sort = "shows",
                sort_dir = "desc",
                datasource,
                date_to,
                category_id,
                negative_keywords = [],
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
                "filter[logic]": "and",
                "filter[filters][0][field]": "date_from",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": "07-02-2022",
                "filter[filters][1][field]": "date_to",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date_to,
                "filter[filters][2][field]": "datasource",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value]": datasource,
                "filter[filters][3][field]": "category_id",
                "filter[filters][3][operator]": "=",
                "filter[filters][3][value]": category_id,
                "filter[filters][4][field]": "negative_keywords",
                "filter[filters][4][operator]": "like",
                ...otherFilters,
            };
            if (negative_keywords.length > 0) {
                params["filter[filters][4][value][]"] = negative_keywords;
            }
            const { data } = await Vue.api.post(
                `/service/seller/get_search_queries_by_category`,
                jsonToFormData(params)
            );
            return data;
        },
        async getSearchQueriesBySku(
            context,
            {
                page = 1,
                page_size = 20,
                sort = "shows",
                sort_dir = "desc",
                datasource,
                date_from,
                date_to,
                sku,
                negative_keywords = [],
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
                "filter[logic]": "and",
                "filter[filters][0][field]": "date_from",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date_from,
                "filter[filters][1][field]": "date_to",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date_to,
                "filter[filters][2][field]": "datasource",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value]": datasource,
                "filter[filters][3][field]": "sku",
                "filter[filters][3][operator]": "=",
                "filter[filters][3][value][]": sku,
                "filter[filters][4][field]": "negative_keywords",
                "filter[filters][4][operator]": "like",
                ...otherFilters,
            };
            if (negative_keywords.length > 0) {
                params["filter[filters][4][value][]"] = negative_keywords;
            }
            const { data } = await Vue.api.post(
                `/service/seller/get_search_queries_by_sku`,
                jsonToFormData(params)
            );
            return data;
        },
        async getSearchQueriesByQueries(
            ctx,
            {
                page = 1,
                page_size = 20,
                sort = "type",
                sort_dir = "asc",
                datasource,
                date_from,
                date_to,
                query,
                negative_keywords = [],
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
                "filter[logic]": "and",
                "filter[filters][0][field]": "date_from",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date_from,
                "filter[filters][1][field]": "date_to",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date_to,
                "filter[filters][2][field]": "query",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": query,
                "filter[filters][3][field]": "datasource",
                "filter[filters][3][operator]": "=",
                "filter[filters][3][value]": datasource,
                "filter[filters][4][field]": "negative_keywords",
                "filter[filters][4][operator]": "like",
                ...otherFilters,
            };
            if (negative_keywords.length > 0) {
                params["filter[filters][4][value][]"] = negative_keywords;
            }
            const { data } = await Vue.api.post(
                "/service/seller/get_search_queries_by_queries",
                jsonToFormData(params)
            );
            return data;
        },
        async getQueriesTop(context, { mp }) {
            let params = {
                mp,
            };
            const { data } = await Vue.api.post(
                `/service/seller/get_queries_top`,
                jsonToFormData(params)
            );
            return data;
        },
    },
};
