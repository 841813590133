//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserDropdown from "@/components/User/Dropdown";
import HelpDropdown from "@/components/HelpDropdown";
import GlobalSearch from "@/components/GlobalSearch/GlobalSearch";
// import ActivityStatuses from "./ActivityStatuses.vue";
const {
  CQ_tarif
} = require("@/components/CarrotQuest/CarrotQuest.js");
export default {
  async created() {
    //this.$store.dispatch("user/getUser");
    let user = await this.$store.dispatch("user/getUser");

    //console.log('AppHeader.45: -> CQ_tarif()')
    CQ_tarif(this.$api, user);
  },
  computed: {
    user() {
      ////console.log('AppHeader.50: this.$store.state.user.user=', this.$store.state.user.user);
      return this.$store.state.user.user;
    }
  },
  components: {
    UserDropdown,
    HelpDropdown,
    GlobalSearch
    // ActivityStatuses,
  }
};