//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Checkbox from "@/components/Checkbox.vue";
import { SlideYUpTransition } from "vue2-transitions";
import { debounce } from "throttle-debounce";
const MIN_SEARCH_LEN = 2; //&:
export default {
  props: {
    placeholder: {
      type: String,
      default: "Глобальный поиск: {scope}"
    },
    hideButton: {
      type: Boolean,
      default: false
    },
    tall: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      header_search: "",
      cb_wb: true,
      cb_ozon: true,
      cb_category: true,
      cb_product: true,
      cb_brand: true,
      cb_seller: true,
      show_settings_popup: false,
      search_params: {
        wb: true,
        ozon: true,
        category: true,
        product: true,
        brand: true,
        seller: true
      },
      input_focused: false,
      show_search_result: false,
      search_results: [],
      search_input_manual_selected: -1,
      search_loading: false,
      loadDebouncedFunction: null,
      collapsed: false
    };
  },
  methods: {
    collapseSearch() {
      // this.collapsed = val;
    },
    toogleSettingsPopup() {
      this.show_settings_popup = !this.show_settings_popup;
    },
    resetSettings() {
      this.cb_wb = true;
      this.cb_ozon = true;
      this.cb_category = true;
      this.cb_product = true;
      this.cb_brand = true;
      this.cb_seller = true;
    },
    applySettings() {
      this.search_params.wb = this.cb_wb;
      this.search_params.ozon = this.cb_ozon;
      this.search_params.category = this.cb_category;
      this.search_params.brand = this.cb_brand;
      this.search_params.seller = this.cb_seller;
      this.search_params.product = this.cb_product;
      this.show_settings_popup = false;
      this.$refs.input.focus();
    },
    focus_input() {
      this.collapseSearch(false);
      this.input_focused = true;
      //!@V: if (this.header_search.length > 0) this.show_search_result=true
      if (this.header_search.length > MIN_SEARCH_LEN) this.show_search_result = true; //!&:
    },

    unfocus_input(forceBlur = false) {
      this.input_focused = false;
      this.show_search_result = false;
      this.search_input_manual_selected = -1;
      if (forceBlur) {
        this.$refs.input.blur();
      }
    },
    change_input() {
      /* //!@V:
      // if (this.header_search.length == 0) this.show_search_result=false
      // if (this.header_search.length > 0 & !this.show_search_result) this.show_search_result=true
      */
      //!&:
      if (this.header_search.length < MIN_SEARCH_LEN) this.show_search_result = false;
      if (this.header_search.length >= MIN_SEARCH_LEN & !this.show_search_result) this.show_search_result = true;
    },
    onClick() {
      this.header_search = "";
    },
    select_prev_search_item() {
      if (this.search_results.length > 0) {
        this.search_input_manual_selected -= 1;
        if (this.search_input_manual_selected < 0) this.search_input_manual_selected = this.search_results.length - 1;
      }
    },
    select_next_search_item() {
      if (this.search_results.length > 0) {
        this.search_input_manual_selected += 1;
        if (this.search_input_manual_selected == this.search_results.length) this.search_input_manual_selected = 0;
      }
    },
    select_search_item(item) {
      if (!item) {
        if (this.search_input_manual_selected !== -1) {
          item = this.search_results[this.search_input_manual_selected];
        } else if (this.search_results && this.search_results.length) {
          item = this.search_results[0];
        } else {
          return;
        }
      }
      const index = this.search_results.indexOf(item);
      let node = this.$refs[`result:${index}`][0];
      if (node.$el) {
        node = node.$el;
      }
      node.click();

      // this.$router.push(item.path)
      this.unfocus_input(true);
      this.header_search = "";
    },
    loadDebounced(config) {
      this.search_loading = true;
      if (!this.loadDebouncedFunction) {
        //this.loadDebouncedFunction = debounce(300, this.load) //@V
        this.loadDebouncedFunction = debounce(500, this.load); //!&:
      }

      this.loadDebouncedFunction(config);
    },
    async load(config) {
      const query = config.query;
      //!@V: if (!query) {
      if (query.length < MIN_SEARCH_LEN) {
        return;
      }
      const highlight = v => {
        /* //! @V: подцветка 1го слова
                let reggie = new RegExp(query, "ig");
                let pos = v.search(reggie)
                if (pos == -1) {
                    return v
                } else {
                    const len_search = query.length
                    let res = `${v.substring(0, pos)}<span class="blue-text">${v.substring(pos, pos+len_search)}</span>${v.substring(pos+len_search)}`
                    return res
                }
                */

        //!&: SF: 2022-01-08 подцветка всех слов из query
        ////let aLIKE = query.trim().split(/[.,<> -]/);
        let aLIKE = query.trim().split(/[, ]/).filter(w => w.length >= MIN_SEARCH_LEN);

        //E(_T(_F), 'aLIKE=', aLIKE)

        var res = v;
        for (let iw = 0; iw < aLIKE.length; iw++) {
          let w = aLIKE[iw].trim();
          if (w == "") continue;
          let re = new RegExp(w, "ig");
          let pos = res.search(re);
          if (pos == -1) {
            continue;
          } else {
            //не попали на разметку?
            let qTag = 0;
            //смотрим влево
            for (let i = pos; i >= 0; i--) {
              if (res.substring(i, i + 1) == ">") {
                qTag = 0;
                break;
              } else if (res.substring(i, i + 1) == "<") {
                qTag = 1;
                break;
              }
            }
            if (qTag == 1) continue;
            //смотрим вправо
            for (let i = pos; i < res.length; i++) {
              if (res.substring(i, i + 1) == "<") {
                qTag = 0;
                break;
              } else if (res.substring(i, i + 1) == ">") {
                qTag = 1;
                break;
              }
            }
            if (qTag == 1) continue;
            let lW = w.length;
            res = `${res.substring(0, pos)}<span class="blue-text">${res.substring(pos, pos + lW)}</span>${res.substring(pos + lW)}`;
          }
        }
        return res;
      };
      const getMpName = v => {
        return {
          wb: "Wildberries",
          ozon: "Ozon"
        }[v];
      };
      const entityDefinitions = {
        category: {
          title: i => highlight(i.c_name_path),
          desc: i => `Категория / ${getMpName(i.mp)}`,
          path: i => ({
            name: "CategoryInfo",
            params: {
              mp: i.mp,
              c_id: i.c_id
            }
          })
        },
        product: {
          title: i => highlight(i.title),
          desc: i => `Товар / SKU ${highlight(i.sku)} / ${getMpName(i.mp)}`,
          path: i => ({
            name: "ProductInfo",
            params: {
              mp: i.mp,
              p_id: i.p_id
            }
          })
        },
        brand: {
          title: i => highlight(i.brand),
          desc: i => `Бренд / ${getMpName(i.mp)}`,
          path: i => ({
            name: "BrandInfo",
            params: {
              mp: i.mp,
              b_id: i.b_id
            }
          })
        },
        seller: {
          title: i => highlight(i.seller),
          desc: i => `Продавец / ${getMpName(i.mp)}`,
          path: i => ({
            name: "SellerInfo",
            params: {
              mp: i.mp,
              s_id: i.s_id
            }
          })
        }
      };
      this.search_loading = true;
      let data = await this.$store.dispatch("common/globalSearch", config);
      this.search_loading = false;
      this.search_results = data.map(i => {
        return {
          title: entityDefinitions[i.entity].title(i),
          desc: entityDefinitions[i.entity].desc(i),
          path: entityDefinitions[i.entity].path(i)
        };
      });
      if (this.search_results.length) {
        this.search_input_manual_selected = 0;
      } else {
        this.search_input_manual_selected = -1;
      }
    }
  },
  created() {
    const onClickOutside = e => this.show_settings_popup = this.$el.contains(e.target) && this.show_settings_popup;
    document.addEventListener("click", onClickOutside);
    this.$on("hook:beforeDestroy", () => document.removeEventListener("click", onClickOutside));
    this.$store.dispatch("user/getUser");
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    config() {
      return {
        query: this.header_search,
        mp: ["wb", "ozon"].filter(i => !!this.search_params[i]),
        entity: ["category", "product", "brand", "seller"].filter(i => !!this.search_params[i])
      };
      // let data = await this.$store.dispatch('common/globalSearch', { c_id: 0 })
    },

    activeSettingsIndicator() {
      return !(this.search_params.wb & this.search_params.ozon & this.search_params.category & this.search_params.product & this.search_params.brand & this.search_params.seller);
    },
    textPlaceholder() {
      if (this.collapsed) {
        return this.placeholder.replace("{scope}", "");
      }
      let scope = "";
      if (this.search_params.wb & this.search_params.ozon & this.search_params.category & this.search_params.product & this.search_params.brand & this.search_params.seller) {
        scope = "по товарам, категориям, SKU, брендам и продавцам";
      } else {
        let res = "";
        if (this.search_params.wb) res += "Wildberries, ";
        if (this.search_params.ozon) res += "OZON, ";
        if (this.search_params.category) res += "категориям, ";
        if (this.search_params.brand) res += "брендам, ";
        if (this.search_params.seller) res += "продавцам, ";
        if (this.search_params.product) res += "товарам, ";
        scope = res.substring(0, res.length - 2);
      }
      return this.placeholder.replace("{scope}", scope);
    }
  },
  watch: {
    async config(config) {
      this.loadDebounced(config);
    }
  },
  components: {
    Checkbox,
    SlideYUpTransition
  }
};